export const ACCEPT_DRAWING_LABELS = {
    not_accept: 'No drawings',
    accept_require: 'Accept and require',
    accept_not_require: 'Accept but don\'t require'
} as const;
export const ADDRESS_TYPE_LABELS = {
    delivery: 'Delivery address',
    customer_company: 'Billing address'
} as const;
export const AXIS_LABELS = {
    x: 'x',
    y: 'y',
    z: 'z'
} as const;
export const BATCH_RECURRING_MODE_LABELS = {
    chamber_volume: 'Printer chamber volume',
    plate_area: 'Plate surface area'
} as const;
export const COMPANY_LANGUAGE_CODE_LABELS = {
    en: 'English',
    'en-au': 'English (Australian)',
    'en-gb': 'English (United Kingdom)',
    ar: '\u0627\u0644\u0644\u063A\u0629 \u0627\u0644\u0639\u0631\u0628\u064A\u0629',
    de: 'Deutsch',
    fr: 'Fran\u00E7ais',
    nl: 'Nederlands',
    ru: '\u0420\u0443\u0441\u0441\u043A\u0438\u0439',
    cs: '\u010Ce\u0161tina',
    it: 'Italiano',
    es: 'Espa\u00F1ol',
    pl: 'Polski',
    th: '\u0E20\u0E32\u0E29\u0E32\u0E44\u0E17\u0E22',
    'zh-hans': '\u7C21\u9AD4\u4E2D\u6587',
    'zh-hk': '\u4E2D\u6E2F',
    'zh-tw': '\u7E41\u9AD4\u4E2D\u2F42',
    ko: '\uD55C\uAD6D\uC778',
    hu: 'Magyar',
    hr: 'Hrvatski',
    sl: 'Slovenski',
    sv: 'Svenska',
    uk: '\u0423\u043A\u0440\u0430\u0457\u043D\u0441\u044C\u043A\u0430',
    da: 'Dansk',
    tr: 'T\u00FCrk\u00E7e',
    no: 'Norsk',
    sr: '\u0421\u0440\u043F\u0441\u043A\u0438',
    he: 'Hebrew'
} as const;
export const COMPANY_SENDERS_VERIFIED_IDENTITY_STATUS_LABELS = {
    pending: 'Pending',
    success: 'Verified',
    failed: 'Unverified',
    temporaryfailure: 'TemporaryFailure'
} as const;
export const COMPANY_SENDERS_VERIFIED_IDENTITY_TYPE_LABELS = {
    email: 'Email address',
    domain: 'Domain'
} as const;
export const COUNTRY_LABELS = {
    AF: 'Afghanistan',
    AL: 'Albania',
    DZ: 'Algeria',
    AD: 'Andorra',
    AR: 'Argentina',
    AO: 'Angola',
    AI: 'Anguilla',
    AQ: 'Antarctica',
    AM: 'Armenia',
    AW: 'Aruba',
    AU: 'Australia',
    AT: 'Austria',
    AZ: 'Azerbaijan',
    BD: 'Bangladesh',
    BB: 'Barbados',
    BY: 'Belarus',
    BE: 'Belgium',
    BZ: 'Belize',
    BJ: 'Benin',
    BM: 'Bermuda',
    BT: 'Bhutan',
    BO: 'Bolivia',
    BA: 'Bosnia and Herzegovina',
    BW: 'Botswana',
    BR: 'Brazil',
    BG: 'Bulgaria',
    BL: 'Burundi',
    BH: 'Bahrain',
    CV: 'Cabo Verde',
    KH: 'Cambodia',
    KW: 'Kuwait',
    CA: 'Canada',
    CL: 'Chile',
    CN: 'China',
    CO: 'Colombia',
    CD: 'Congo',
    CR: 'Costa Rica',
    HR: 'Croatia',
    CU: 'Cuba',
    CY: 'Cyprus',
    CZ: 'Czech Republic',
    DK: 'Denmark',
    DO: 'Dominican Republic',
    EC: 'Ecuador',
    EG: 'Egypt',
    SV: 'El Salvador',
    EE: 'Estonia',
    ET: 'Ethiopia',
    FI: 'Finland',
    FR: 'France',
    GE: 'Georgia',
    DE: 'Germany',
    GI: 'Gibraltar',
    GR: 'Greece',
    GL: 'Greenland',
    GP: 'Guadeloupe',
    GT: 'Guatemala',
    HT: 'Haiti',
    HN: 'Honduras',
    HK: 'Hong Kong',
    HU: 'Hungary',
    IS: 'Iceland',
    IN: 'India',
    ID: 'Indonesia',
    IR: 'Iran',
    IQ: 'Iraq',
    IE: 'Ireland',
    IL: 'Israel',
    IT: 'Italy',
    JM: 'Jamaica',
    JO: 'Jordan',
    JP: 'Japan',
    KZ: 'Kazakhstan',
    KE: 'Kenya',
    KR: 'Korea, Republic of',
    KG: 'Kyrgyzstan',
    LV: 'Latvia',
    LB: 'Lebanon',
    LS: 'Lesotho',
    LR: 'Liberia',
    LY: 'Libya',
    LI: 'Liechtenstein',
    LT: 'Lithuania',
    LU: 'Luxembourg',
    MO: 'Macao',
    MK: 'Macedonia, The Former Yugoslav Republic of',
    MY: 'Malaysia',
    MV: 'Maldives',
    MT: 'Malta',
    MX: 'Mexico',
    MD: 'Moldova, Republic of',
    MC: 'Monaco',
    ME: 'Montenegro',
    MA: 'Morocco',
    NP: 'Nepal',
    NL: 'Netherlands',
    NC: 'New Caledonia',
    NZ: 'New Zealand',
    NI: 'Nicaragua',
    NG: 'Nigeria',
    NF: 'Norfolk Island',
    NO: 'Norway',
    OM: 'Oman',
    PK: 'Pakistan',
    PA: 'Panama',
    PY: 'Paraguay',
    PE: 'Peru',
    PH: 'Philippines',
    PL: 'Poland',
    PT: 'Portugal',
    PR: 'Puerto Rico',
    QA: 'Qatar',
    RO: 'Romania',
    RU: 'Russian Federation',
    WS: 'Samoa',
    SM: 'San Marino',
    SA: 'Saudi Arabia',
    RS: 'Serbia',
    SG: 'Singapore',
    SK: 'Slovakia',
    SI: 'Slovenia',
    SO: 'Somalia',
    ZA: 'South Africa',
    ES: 'Spain',
    LK: 'Sri Lanka',
    SD: 'Sudan',
    SE: 'Sweden',
    CH: 'Switzerland',
    TW: 'Taiwan',
    TJ: 'Tajikistan',
    TH: 'Thailand',
    TO: 'Tonga',
    TN: 'Tunisia',
    TR: 'Turkey',
    TM: 'Turkmenistan',
    UG: 'Uganda',
    UA: 'Ukraine',
    AE: 'United Arab Emirates',
    GB: 'United Kingdom',
    US: 'United States',
    UY: 'Uruguay',
    UZ: 'Uzbekistan',
    VU: 'Vanuatu',
    VE: 'Venezuela, Bolivarian Republic of',
    VN: 'Vietnam',
    YE: 'Yemen',
    ZW: 'Zimbabwe'
} as const;
export const CURRENCY_DECIMAL_DIGITS_LABELS = {
    '0': '0.',
    '2': '0.00'
} as const;
export const CURRENCY_FORMAT_DECIMAL_SEPARATOR_LABELS = {
    '.': '.',
    ',': ','
} as const;
export const CURRENCY_FORMAT_SIGN_POSITION_LABELS = {
    left: 'Left',
    right: 'Right'
} as const;
export const CURRENCY_FORMAT_THOUSAND_SEPARATOR_LABELS = {
    '.': '.',
    ',': ',',
    '\u00A0': ' '
} as const;
export const DISCOUNT_TYPE_LABELS = {
    promo: 'Promo discount',
    client: 'Client discount',
    order: 'Order discount',
    batch: 'Batch discount',
    hourly: 'Hourly discount',
    sq_cm_of_material: '2d cutting material discount'
} as const;
export const DOCUMENT_TYPE_LABELS = {
    invoices: 'Tax compliant invoice',
    proforma: 'Pro forma invoice/PO'
} as const;
export const EXECUTION_TYPE_LABELS = {
    layer_thickness: 'Layer thickness',
    filling: 'Filling',
    color: 'Color'
} as const;
export const FEATURE_TYPE_LABELS = {
    cuboids: 'Any direction',
    axial: 'Axial',
    radial: 'Radial',
    single_step_reduction: 'Reduction'
} as const;
export const FILE_TYPE_LABELS = {
    internal: 'Internal',
    client: 'Client',
    sheet_nesting: 'Sheet Nesting'
} as const;
export const FORM_TYPE_LABELS = {
    post_production_default: 'post_production_default',
    post_production_coloring: 'post_production_coloring'
} as const;
export const GEOMETRY_TYPE_LABELS = {
    shaft: 'Shaft',
    disk: 'Disk',
    sheet: 'Sheet',
    sheetmetal: 'Sheetmetal',
    flat_sheet: 'Flat sheet',
    other: 'Other'
} as const;
export const INITIAL_STATUS_LABELS = {
    in_progress: 'in_progress',
    ready: 'ready',
    failed: 'failed'
} as const;
export const LOGGING_LEVEL_LABELS = {
    info: 'INFO',
    warning: 'WARNING',
    error: 'ERROR'
} as const;
export const MATERIAL_SELECTION_MODE_LABELS = {
    preselection: 'Preselection (CNC only)',
    repeat_specs: 'Repeat Specs',
    full_preselection: 'Full preselection'
} as const;
export const MODEL_UNITS_LABELS = {
    mm: 'Millimeters',
    cm: 'Centimeters',
    in: 'Inches'
} as const;
export const MODELS_TYPE_LABELS = {
    original: 'original',
    repaired: 'repaired'
} as const;
export const ORDER_CREATE_STATUS_LABELS = {
    created: 'created',
    waiting_for_review: 'waiting_for_review',
    placed: 'placed',
    firm_offer_sent: 'firm_offer_sent',
    initial: 'initial'
} as const;
export const ORDER_PRICE_CORRECTION_TYPE_LABELS = {
    general: 'General',
    delivery: 'Delivery'
} as const;
export const ORDER_STATUS_LABELS = {
    created: 'Not finished',
    initial: 'Initial',
    waiting_for_review: 'Waiting for review',
    firm_offer_sent: 'Firm offer sent',
    placed: 'Placed',
    po_provided: 'PO provided',
    waiting_for_payment: 'Waiting for payment',
    on_hold: 'On hold',
    free_for_manufacturing: 'Free for manufacturing',
    planning_confirmed: 'Planning confirmed',
    manufacturing: 'Manufacturing',
    post_production: 'Post production',
    re_manufacturing: 'Re-printing',
    ready_for_shipping: 'Ready for shipping',
    shipped: 'Shipped',
    ready_for_pickup: 'Ready for pickup',
    delivered: 'Delivered',
    po_payment_due: 'PO Payment due',
    cancelled_by_user: 'Cancelled by customer',
    cancelled_by_admin: 'Cancelled by admin'
} as const;
export const ORDERS_REVIEW_TYPE_LABELS = {
    instant_payment: 'Instant payment',
    manual_review: 'Payment after manual review',
    payment_or_manual_review: 'Payment or manual review decided by user'
} as const;
export const PAYMENT_METHOD_LABELS = {
    paypal: 'PayPal',
    stripe: 'Stripe',
    payfast: 'PayFast',
    'wires-only': 'Other payment (outside DigiFabster\'s purview)'
} as const;
export const PAYMENT_STATUS_LABELS = {
    denied: 'denied',
    needs_initialization: 'needs_initialization',
    allowed: 'allowed',
    processing: 'processing',
    paid: 'paid',
    cancelled: 'cancelled'
} as const;
export const POLL_USERS_CHOICE_LABELS = {
    price_too_high: 'I think the price is too high',
    time_too_long: 'I think the lead time is too long',
    no_material: 'I did not find the material I need',
    extra_time: 'I need extra time to decide ',
    other: 'Other'
} as const;
export const PRICE_UNITS_LABELS = {
    per_model: 'per_model',
    'per_cm_2': 'per_cm_2',
    'per_cm_3': 'per_cm_3',
    per_bb: 'per_bb'
} as const;
export const PRICING_METHOD_LABELS = {
    bar_stocks: 'Bar stocks',
    round_bar_stocks: 'Round bar stocks',
    per_gram: 'Per gram'
} as const;
export const PROCESSING_STATUS_LABELS = {
    in_progress: 'In progress',
    success: 'Success',
    failed: 'Failed'
} as const;
export const SERVICE_LABELS = {
    stripe: 'Stripe',
    paypal: 'PayPal',
    easypost: 'EasyPost',
    hubspot: 'HubSpot',
    quickbooks_online: 'QuickBooks Online',
    salesforce: 'SalesForce',
    sharepoint: 'SharePoint',
    trello: 'Trello',
    xero: 'Xero',
    zoho: 'Zoho',
    webhooks: 'Webhooks'
} as const;
export const SHAPE_TYPE_LABELS = {
    '': 'Any',
    simple: 'Simple',
    countersunk: 'Countersunk',
    counterdrilled: 'Counterdrilled',
    counterbored: 'Counterbored'
} as const;
export const SHIPPING_TYPE_LABELS = {
    international: 'International',
    domestic: 'Domestic',
    city: 'City',
    'pick-up': 'Pick-up'
} as const;
export const SOURCE_LABELS = {
    email: 'Email',
    auto: 'Auto'
} as const;
export const SPEC_STRING_TEMPLATE_LABELS = {
    layer_thickness: 'Layer thickness',
    filling: 'Filling',
    color: 'Color',
    post_production: 'Post production',
    lead_time: 'Lead time',
    tolerance: 'Tolerance',
    thickness: 'Thickness',
    features: 'Features',
    dfm_features: 'DFM features',
    extra_fieldsets: 'Additional Fields',
    technology: 'Technology'
} as const;
export const TBD_REASON_LABELS = {
    line_item_total: 'Your order will be held for a manual review as one or more of the items totals exceed the price limits.',
    order_total: 'Your order will be held for a manual review as your order total exceeds the price limits.',
    machine_quantity: 'Your order will be held for a manual review as one or more of the items exceed the quantity limits.',
    non_cad: 'Your order will be held for a manual review as it includes files that can\u2019t be automatically processed.',
    lead_time_quantity: 'Your order will be held for a manual review as one or more of the items exceed the quantity limits.'
} as const;
export const THROUGH_LABELS = {
    '': 'Allow both',
    through: 'Through-only',
    not_through: 'Not through-only'
} as const;
export const TOPOLOGY_TYPE_LABELS = {
    hole: 'Hole',
    slot: 'Slot',
    pocket: 'Pocket',
    notch: 'Notch',
    reduction: 'Reduction'
} as const;
export const WIDGET_SIGN_IN_MODE_LABELS = {
    upload: 'Upon upload model',
    add_to_cart: 'Upon adding a product to the cart'
} as const;
export const ZIP_GROUPING_METHOD_LABELS = {
    group_by_object_model: 'Group by models',
    group_by_purchase: 'Group by line-items'
} as const;
